"use client";

import type React from "react";
import { useEffect } from "react";
import { sendError } from "../components/ErrorBoundary/helpers";

type Props = {
    error: Error;
};

const Error: React.FC<Props> = ({ error }: Props) => {
    useEffect(() => {
        sendError(error);
    }, [error]);

    return null;
};

export default Error;
